<template>
  <div class="full-width full-height">
    <riseup-header />
    <div class="full-width full-height padding-vertical-m padding-horizontal-sm">
      <div class="ri-bold-title">מעולה, אנחנו על זה 👌</div>
      <div class="ri-title margin-top-s">בדקות הקרובות המערכת מעדכנת את המידע שלך ושולחת לך מייל עם טיפים ותובנות מותאמות אישית. זה בחינם 💌</div>
    </div>

  </div>
</template>

<script>

import RiseupHeader from '@/base-components/app-header/RiseupHeader';

export default {
  name: 'DormantInsights',
  components: {
    RiseupHeader,
  },

};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

</style>
